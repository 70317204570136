<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form ref="form" @submit.prevent="submit">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input">Category Title</label>
                      <input type="text" class="form-control" id="formrow-Firstname-input"
                        v-model.trim="$v.form.title.$model" placeholder="Enter Category Name" />
                      <div v-if="$v.form.title.$error">
                        <div class="error" v-if="$v.form.title.$error">
                          Title is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.title.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input">Category Slug</label>
                      <input type="text" class="form-control" id="formrow-Lastname-input"
                        v-model.trim="$v.form.slug.$model" placeholder="Enter Category Slug" />
                      <div v-if="$v.form.slug.$error">
                        <div class="error" v-if="$v.form.slug.$error">
                          Slug is Required!
                        </div>
                        <!-- <div class="error" v-if="!$v.form.slug.alpha">
                          Please Enter Letters only
                        </div> -->
                      </div>
                      <!-- <div class="error" v-if="$v.form.slug.$error">
                        Slug is required
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="image">Select Category Image</label>
                      <input type="button" id="image" @click="openPopup" v-model="buttonTypeVal"
                        placeholder="Enter Category Slug" value="Select Image" />
                      <div v-if="$v.form.icon.$error">
                        <div class="error" v-if="$v.form.icon.$error">
                          Icon is Required!
                        </div>
                      </div>
                    </div>
                    <input type="hidden" v-model.trim="$v.form.icon.$model" />
                  </div>
                </div>
                <div class="d-flex flex-wrap gap-3 mt-3">
                  <SubmitButton :processing="processing" type="submit"
                    class="btn btn-primary waves-effect waves-light w-md" text="Add Category" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="category-modal" hide-footer title="Using Component Methods" size="xl">
      <p v-if="iconSelection">Please select at least one icon</p>
      <APILoader v-if="loading" :loading="loading" class="align-middle text-center"></APILoader>
      <div v-else class="d-flex gap-3 text-center">
        <div v-for="(img, index) in images" :key="index" @click="selectImg(index, img)"
          :class="['d-flex', 'flex-column', 'justify-content-center', 'gap-2', { 'img-category': selectedIndex === index }, 'cursor-pointer']">
          <img :src="img.url" class="img-fluid mx-auto" alt="category-image" height="30px" width="30px">
          <p class="mb-0">{{ img.name }}</p>
        </div>
      </div>
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Ok</b-button>
    </b-modal>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import { required, alpha } from "vuelidate/lib/validators";
export default {
  name: "add-category",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Add Category",
      processing: false,
      buttonTypeVal: "Select an category image",
      loading: true,
      images: [],
      iconSelection: false,
      form: {
        title: null,
        slug: null,
        icon: null,
      },
      selectedIndex: false,
    };
  },
  validations: {
    form: {
      title: {
        required,
        // alpha,
      },
      slug: {
        required,
        // alpha,
      },
      icon: {
        required
      }
    },
  },
  methods: {
    selectImg(index, img) {
      this.iconSelection = false;
      this.selectedIndex = index;
      this.form.icon = img.url;
    },
    openPopup() {
      this.$refs['category-modal'].show();
      if (this.images.length < 1) {
        this.categoryImages();
      }
    },
    hideModal() {
      if (this.form.icon) {
        this.iconSelection = false;
        return this.$refs['category-modal'].hide();
      }
      this.iconSelection = true;
    },
    categoryImages() {
      this.$axios.get('category-images')
        .then((response) => {
          this.images = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.processing = true;
        this.$axios
          .post("categories", this.form)
          .then((response) => {
            this.$router.push({ path: "/category/listing" });
            this.triggerSwal(response.data.message, "success");
            // console.log(response.data);
            this.$refs.form.reset();
            this.processing = false;
          })
          .catch((error) => {
            // console.log(error.response.data.message);
            this.handleErrorResponse(error.response, "error");
            this.processing = false;
          });
      }
    },
  },
};
</script>